@import "core";
.modal-container.modal-notifications {
	.modal-body {
		padding-top: 1rem;
		padding-bottom: calc(9.7rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}

	// .notifications-list {}

		.list-item {
			padding: 1rem 1rem 1rem 1.5rem;
			display: flex;
			align-items: center;

			&.unread {
				background-color: $color-bg;

				.item-icon {
					color: $color-primary;
				}
			}

			&.placeholder {
				width: 100%;
				height: 4.8rem;
			}

			+ .list-item {
				border-top: .1rem solid $color-border;
			}
		}

			.item-icon {
				font-size: 2rem;
				margin-right: 1.5rem;
				transition: color $transition-hover ease;
			}

			.item-text {
				flex: 1 0 0;
				line-height: 1.2em;
			}

			.item-datetime {
				color: $color-text-light;
				font-size: 1.2rem;
				display: block;
				margin-top: 1rem;
				text-align: right;
			}
}